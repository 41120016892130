@import "@/styles/variables.module.scss";

.home {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;  /* 水平方向居左 */
  justify-content: flex-start;  /* 网格整体在容器中水平分布 */
  align-items: flex-start;    /* 垂直方向居左 */
  align-content: flex-start;    /* 网格整体在容器中垂直分布 */
  padding: 12px;
  min-height: 100%;
}


.home__box {
  position: relative;
  height: auto;
}

.home__image {
  width: 100%;
  height: auto;
  object-fit: contain;
}
